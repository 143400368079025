import { graphql, Link } from "gatsby";
import React from "react"
import styled from "styled-components";
import Layout from "../components/layout"
import { small } from "../components/responsive";
import Seo from "../components/seo";
import { PageProps } from "../shared";
import { green } from "../shared/colors";
import ReactMarkdown from "react-markdown"

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h2`
    padding: 20px;
`;

const Content = styled.div<{ odd: boolean }>`    
    display: flex;
    width: 100%;
    flex-direction: ${props => props.odd ? 'row-reverse' : 'row'};
    @media(max-width: ${small}) {
        flex-direction: column;
    }

    > * {
        background: white;
        flex: 0 0 50%;
        min-height: 250px;
    }

    > div > p {
        text-align: ${props => props.odd ? 'right' : 'left'};
        @media(max-width: ${small}) {
            text-align: center;
        }
        &:before, &:after { 
            height: 2px;
            content: '';
            background: ${green};
            display: block;
            width: 100px;
            margin: 20px auto;
        }
    }
`;

const ContentImage = styled.div<{src: string}>`
    background: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media(max-width: ${small}) {
        width: 100%;
    }
`;

const ContentText = styled.p`
    position relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2.5em;
    margin: 0;
    padding: 20px;
    white-space: pre-wrap;
`;

type AboutProps = { headerImg: string, subtitle: string, sections: { text: string, image: string }[] };

export const query = graphql`
{
  pageData: aboutJson {
    headerImg
    subtitle
    sections {
      text
      image
    }
  }
}
`;

const About = (data: PageProps<AboutProps>) => {
    const { headerImg, subtitle, sections } = data.data.pageData;
    return (<>
        <Layout title="About Us" transparent={true} header={headerImg}>
            <Seo title='About' />
            <Wrapper>
                <Header>{subtitle}</Header>
                {sections.map((s, index) => (<Content odd={index % 2 === 1} key={index}>
                    <ContentImage src={s.image}></ContentImage>
                    <div>
                        <ContentText><ReactMarkdown>{s.text}</ReactMarkdown></ContentText>
                    </div>
                </Content>))}
            </Wrapper>
        </Layout>
    </>)
}

export default About;